<script setup lang="ts">

interface IProps {
  path: string | null,
  url: string | null,
  alt: string,
  loading?: string,
  quality?: number,
  width: number,
  height: number,
}

const config = useRuntimeConfig()

const props = withDefaults(defineProps<IProps>(), {
  loading: "eager",
  quality: 100,
});

const src = computed(() => config.public.imageProvider === 'ipx' ? props.url : props.path)
</script>

<template>
  <div class="book-cover bg-[url('/img/image-placeholder.svg')] bg-cover rounded-lg overflow-hidden">
    <NuxtImg
        :provider="config.public.imageProvider"
        :src="src"
        :alt="alt"
        :loading="loading"
        :quality="quality"
        :width="width"
        :height="height"
        format="webp"
    />
  </div>
</template>

<style scoped>

</style>